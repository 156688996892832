exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-application-privacy-policy-js": () => import("./../../../src/pages/application/privacy-policy.js" /* webpackChunkName: "component---src-pages-application-privacy-policy-js" */),
  "component---src-pages-application-terms-and-conditions-js": () => import("./../../../src/pages/application/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-application-terms-and-conditions-js" */),
  "component---src-pages-confirm-js": () => import("./../../../src/pages/confirm/[...].js" /* webpackChunkName: "component---src-pages-confirm-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jogi-adatvedelem-js": () => import("./../../../src/pages/jogi/adatvedelem.js" /* webpackChunkName: "component---src-pages-jogi-adatvedelem-js" */),
  "component---src-pages-jogi-aszf-js": () => import("./../../../src/pages/jogi/aszf.js" /* webpackChunkName: "component---src-pages-jogi-aszf-js" */),
  "component---src-pages-jogi-impresszum-js": () => import("./../../../src/pages/jogi/impresszum.js" /* webpackChunkName: "component---src-pages-jogi-impresszum-js" */),
  "component---src-pages-jogi-suti-beallitasok-js": () => import("./../../../src/pages/jogi/suti-beallitasok.js" /* webpackChunkName: "component---src-pages-jogi-suti-beallitasok-js" */),
  "component---src-pages-legal-cookie-policy-js": () => import("./../../../src/pages/legal/cookie-policy.js" /* webpackChunkName: "component---src-pages-legal-cookie-policy-js" */),
  "component---src-pages-legal-impressum-js": () => import("./../../../src/pages/legal/impressum.js" /* webpackChunkName: "component---src-pages-legal-impressum-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-terms-and-conditions-js": () => import("./../../../src/pages/legal/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-legal-terms-and-conditions-js" */),
  "component---src-pages-private-data-request-js": () => import("./../../../src/pages/private/data-request.js" /* webpackChunkName: "component---src-pages-private-data-request-js" */),
  "component---src-pages-private-js": () => import("./../../../src/pages/private/[...].js" /* webpackChunkName: "component---src-pages-private-js" */),
  "component---src-templates-article-post-js": () => import("./../../../src/templates/article-post.js" /* webpackChunkName: "component---src-templates-article-post-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-letter-js": () => import("./../../../src/templates/letter.js" /* webpackChunkName: "component---src-templates-letter-js" */)
}

